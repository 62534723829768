import { createSelector, createSlice, Dictionary, PayloadAction } from '@reduxjs/toolkit';
import IPayer from 'api/models/payer.model';
import { LoadingStatus } from 'interfaces/loadingStatus';
import { filter, isEmpty, map } from 'lodash';
import { RootState } from 'state/store';
import { addPayer, getPayer, getPayers, updatePayer } from './payers.actions';
import { v4 as uuid } from 'uuid';

const initialState: PayersState = {
    payers: {},
    loading: LoadingStatus.Idle,
    loadingSelectedPayer: LoadingStatus.Idle,
    saving: LoadingStatus.Idle,
    showPayersFilters: ['isActive'],
    isAdding: false,
};

const payersSlice = createSlice({
    name: 'payers',
    initialState,
    reducers: {
        toggleShowPayersFilter: (state, { payload }: PayloadAction<'isActive' | 'isPending'>) => {
            const indexOfFilter = state.showPayersFilters.indexOf(payload);
            if (indexOfFilter > -1) {
                state.showPayersFilters = [
                    ...state.showPayersFilters.slice(0, indexOfFilter),
                    ...state.showPayersFilters.slice(indexOfFilter + 1),
                ];
            } else {
                state.showPayersFilters = [...state.showPayersFilters, payload];
            }
        },
        updateSelectedPayerField: (state, action: PayloadAction<{ path: keyof IPayer; value: any }>) => {
            const { path, value } = action.payload;
            if (state.selectedPayer) (state.selectedPayer as any)[path] = value;
        },
        cleanupSelectedPayer: (state) => {
            state.selectedPayer = undefined;
        },
        addNewPayer: (state: PayersState) => {
            state.selectedPayer = {
                id: uuid(),
                specialty: 'Dental',
                name: '',
                ediPayerId: '',
                payerId: '',
                street1: '',
                street2: '',
                city: '',
                state: '',
                zip: '',
                phone: '',
                payerType: '',
                benefitPlanId: '',
                requiresEnrollment: false,
                dentalEnrollmentInstructions: '',
                notes: '',
                lastUpdated: '',
                isDeleted: false,
                isEncounterRatePayer: false,
            };
            state.isAdding = true;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPayers.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getPayers.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.payers = action.payload;
            })
            .addCase(getPayers.rejected, (state) => {
                state.loading = LoadingStatus.Failed;
            })
            .addCase(getPayer.pending, (state) => {
                state.loadingSelectedPayer = LoadingStatus.Pending;
            })
            .addCase(getPayer.fulfilled, (state, action) => {
                state.loadingSelectedPayer = LoadingStatus.Completed;
                state.selectedPayer = action.payload;
            })
            .addCase(getPayer.rejected, (state) => {
                state.loadingSelectedPayer = LoadingStatus.Failed;
            })
            .addCase(updatePayer.pending, (state) => {
                state.saving = LoadingStatus.Pending;
            })
            .addCase(updatePayer.fulfilled, (state, action) => {
                state.saving = LoadingStatus.Completed;
                if (state.selectedPayer) state.selectedPayer = action.payload;
                if (!isEmpty(state.payers)) state.payers[action.payload.id] = action.payload;
                state.selectedPayer = undefined;
            })
            .addCase(updatePayer.rejected, (state) => {
                state.saving = LoadingStatus.Failed;
            })
            .addCase(addPayer.pending, (state) => {
                state.saving = LoadingStatus.Pending;
            })
            .addCase(addPayer.fulfilled, (state, action) => {
                state.saving = LoadingStatus.Completed;
                state.payers[action.payload.id] = action.payload;
                state.selectedPayer = undefined;
            })
            .addCase(addPayer.rejected, (state) => {
                state.saving = LoadingStatus.Failed;
            });
    },
});

export const payersState = (state: RootState) => state.payers;

export const payersData = createSelector(payersState, (payerState) => payerState.payers);
export const loadingSelectedPayer = createSelector(payersState, (payerState) => payerState.loadingSelectedPayer);
export const payersLoading = createSelector(payersState, (payerState) => payerState.loading);
export const selectShowPayersFilters = createSelector(payersState, (payerState) => payerState.showPayersFilters);

function isPayerPending(payer: IPayer) {
    return !!payer.isPending;
}

function isPayerActive(payer: IPayer) {
    return !payer.isDeleted && !payer.isPending;
}

const filterFuncLookup: Record<PayerFilters, (payer: IPayer) => boolean> = {
    isActive: isPayerActive,
    isPending: isPayerPending,
};

function shouldShowPayer(payerFilters: PayerFilters[], payer?: IPayer) {
    if (!payer) return false;
    for (const filter of payerFilters) {
        if (filterFuncLookup[filter](payer)) return true;
    }
    return false;
}

export const payersAsList = createSelector(payersState, selectShowPayersFilters, (payerState, payerFilters) =>
    !payerFilters.length
        ? (map(payerState.payers) as IPayer[])
        : (filter(payerState.payers, (p) => shouldShowPayer(payerFilters, p)) as IPayer[]),
);

export const selectedPayer = createSelector(payersState, (payerState) => payerState.selectedPayer);

export const { toggleShowPayersFilter, updateSelectedPayerField, cleanupSelectedPayer, addNewPayer } = payersSlice.actions;

export default payersSlice.reducer;

type PayersState = {
    payers: Dictionary<IPayer>;
    selectedPayer?: IPayer;
    loadingSelectedPayer: LoadingStatus;
    loading: LoadingStatus;
    saving: LoadingStatus;
    showPayersFilters: PayerFilters[];
    isAdding: boolean;
};

export type PayerFilters = 'isActive' | 'isPending';
